
<template>
  <div>
    <super-header />

    <div class="categories" v-if="entryForm">
      <entry-form actionText="Create" @submitted="formSubmitted"></entry-form>
    </div>
  </div>
</template>

<script>
import SuperHeader from 'components/super/SuperHeader.vue';
import {ENV_URL} from '../../environment.js';
import EntryForm from 'components/super/EntryForm.vue';
import axios from 'axios';
export default {

  name: 'directory-view',

  components: {
    SuperHeader,
    EntryForm,
  },
  data () {
    return {
      ENV_URL: ENV_URL,
    }

  },
  created() {
    this.getData();
  },

  computed: {
    entryForm() {
      return this.$store.state.entryForm;
    },
    selectedSingleCategories() {
      return this.$store.state.selectedSingleCategories;
    }
  },

  mounted() {

  },

  methods: {
    
    formSubmitted() {

      this.$store.dispatch('LOADING', true);

      var laravelAuthToken = localStorage.getItem('laravelAuthToken');

      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };

      const selected = this.selectedSingleCategories.category;

      const parent_id = selected ? selected['id'] : 0;

      const level = selected ? selected['level'] + 1 : 0;

      const item_data = {
        entryFormFields: this.entryForm.fields,
        parent_id: parent_id,
        level: level,
      }

      // POST: /super/categories/create/:type
      let uri = ENV_URL + this.$route.path;
      axios.post(uri, item_data, authHeaders).then((response) => {
        this.$store.dispatch('LOADING', false);
        alert('Your item was created!');
      })
      .catch((error) => {
        console.log('app error: ', error.message);
        this.$store.dispatch('LOADING', false);
        alert(error.message);
      })
    },
    getData() {
      // GET: /super/categories/create/:type
      const uri = ENV_URL + this.$route.path;
      this.$store.dispatch('SUPER_CATEGORIES_CREATE_TYPE', uri);
    }
  },
}
</script>

<style lang="scss">

</style>
